<template>
  <div>
    <div>
      <div
        v-if="showMenu"
        class="menu-overlay"
        @click="showMenu = false"
      />
      <div class="content">
        <!-- Main Content Here -->
      </div>
      <div class="bg-purple p-md-1 pl-75 pr-75 pb-75 pt-75">
        <div
          class="d-flex align-items-center justify-content-between container-header"
        >
          <div class="d-md-block d-none">
            <div
              class="d-flex align-items-center"
              style="position: relative"
            >
              <button
                class="btns bg-transparent p-0 x-hamburger -switcher js-theme-toggle"
                @click="toggleMenu"
              >
                <span />
                <span />
                <span />
              </button>

              <a
                href="/"
                class="ml-n1 p-25 cursor-pointer d-flex align-items-center"
              >
                <img
                  src="/logo/logo-sv.png"
                  alt="Logo"
                  class="logo-brand-txt"
                >
                <h3 class="mb-0 ml-25 text-primary">สลากสุวรรณภูมิ</h3>
              </a>

              <div
                class="backdrops"
                :style="{ display: isMenuOpen ? 'block' : 'none' }"
                @click="closeMenu"
              />
              <div
                class="menu"
                :style="{ display: isMenuOpen ? 'block' : 'none' }"
                :class="{ 'menu-open': isMenuOpen }"
              >
                <ul>
                  <li>
                    <a href="/">
                      <div class="d-flex align-items-center menu-item-txt">
                        <img
                          src="/suvan/icon-menu/home.svg"
                          alt=""
                        >
                        <span>หน้าแรก</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="d-flex align-items-center menu-item-txt">
                      <img
                        src="/suvan/icon-menu/coin.svg"
                        alt=""
                      >
                      <span>การเงิน</span>
                    </div>

                    <div class="sub-menu-item">
                      <a href="/deposit/bank"> ฝากเงิน </a>
                      <a href="/withdraw"> ถอนเงิน </a>
                    </div>
                  </li>

                  <li>
                    <a href="/reward">
                      <div class="d-flex align-items-center menu-item-txt">
                        <img
                          src="/suvan/icon-menu/reward.svg"
                          alt=""
                        >
                        <span>ผลรางวัลสลากรวม

                          <img
                            src="/suvan/icon-menu/hot.gif"
                            alt="hot"
                            class="ml-n25"
                            style="width: 30px !important"
                          >
                        </span>
                      </div>
                    </a>
                  </li>

                  <li>
                    <div class="d-flex align-items-center menu-item-txt">
                      <img
                        src="/suvan/icon-menu/lotto.svg"
                        alt=""
                        style="margin-top: 2px"
                      >
                      <span>รายการสลาก</span>
                    </div>

                    <div class="sub-menu-item">
                      <a href="/check-result"> ตรวจผลสลากฯ </a>

                      <a href="/lottery/cart"> ตะกร้า </a>

                      <a href="/lottery/order"> กระเป๋าสลาก </a>
                    </div>
                  </li>

                  <li>
                    <div class="d-flex align-items-center menu-item-txt">
                      <img
                        src="/suvan/icon-menu/gift-fill.svg"
                        alt=""
                      >
                      <span>กิจกรรม</span>
                    </div>

                    <div class="sub-menu-item">
                      <a href="/point">
                        Point สะสมแต้ม
                        <img
                          src="/suvan/icon-menu/hot.gif"
                          alt="hot"
                          class="ml-n25"
                          width="30"
                        >
                      </a>

                      <a href="/lucky-box">
                        กล่องสุ่มเลข
                        <img
                          src="/suvan/icon-menu/hot.gif"
                          alt="hot"
                          class="ml-n25"
                          width="30"
                        >
                      </a>

                      <a href="/lucky-wheel"> กงล้อรับโชค </a>

                      <!-- <a href="/">
                        ทำนายฝัน
                      </a> -->
                    </div>
                  </li>

                  <li>
                    <a href="/contact">
                      <div class="d-flex align-items-center menu-item-txt">
                        <img
                          src="/suvan/icon-menu/phone.svg"
                          alt=""
                        >
                        <span>ติดต่อเจ้าหน้าที่</span>
                      </div>
                    </a>
                  </li>

                  <!-- <li>
                    <a href="/">
                      <div class="d-flex align-items-center menu-item-txt">
                        <img
                          src="/suvan/icon-menu/concent.svg"
                          alt=""
                        >
                        <span>นโยบายและเงื่อนไข</span>
                      </div>
                    </a>
                  </li> -->

                  <li>
                    <a href="/about">
                      <div class="d-flex align-items-center menu-item-txt">
                        <img
                          src="/suvan/icon-menu/contact.svg"
                          alt=""
                        >
                        <span>เกี่ยวกับเรา</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="d-none d-md-none d-lg-block">
            <div class="d-flex align-items-center">
              <router-link
                :to="{ name: 'home' }"
                class="text-primary mr-2"
              >
                หน้าแรก
              </router-link>

              <router-link
                v-if="userData"
                :to="{ name: 'lottery' }"
                class="text-primary mr-2"
              >
                ซื้อสลาก
              </router-link>

              <a
                v-if="userData"
                class="text-primary mr-2"
                @click="betHuayja()"
              >
                แทงหวยออนไลน์
              </a>

              <router-link
                v-if="userData"
                :to="{ name: 'bank' }"
                class="text-primary mr-2"
              >
                ฝากเงิน
              </router-link>

              <!-- <router-link
                v-if="userData"
                :to="{ name: 'withdraw' }"
                class="text-primary mr-2 "
              >
                ถอนเงิน
              </router-link> -->

              <router-link
                :to="{ name: 'check-result' }"
                class="text-primary mr-2"
              >
                ตรวจผลสลากฯ
              </router-link>

              <router-link
                v-if="userData"
                :to="{ name: 'order-history' }"
                class="text-primary mr-2"
              >
                ประวัติการสั่งซื้อ
              </router-link>

              <router-link
                v-if="userData && userData.zean == 0"
                :to="{ name: 'invite-friend' }"
                class="text-primary mr-2"
              >
                แนะนำเพื่อน
              </router-link>

              <router-link
                v-if="userData && userData.zean == 1"
                :to="{ name: 'invite-by-zian' }"
                class="text-primary mr-2"
              >
                เซียนแนะนำเพื่อน
              </router-link>

              <div
                v-if="!userData"
                class="flag-lang mr-50"
              >
                <div class="flag">
                  <img
                    src="/suvan/flag/flag-th.png"
                    alt="flag"
                    class="logo-flag-txt"
                  >
                </div>
              </div>

              <div
                v-if="!userData"
                class="user-icon cursor-pointer"
                style="position: relative"
                @click="showLogin"
              >
                <i class="fas fa-chevron-down mr-25" />
                <img
                  :src="ShowProfile()"
                  alt="img-user"
                  class="ic-profile"
                >

                <div
                  class="backdrops"
                  :style="{ display: menuLogin ? 'block' : 'none' }"
                  @click="closeLogin"
                />
                <div
                  class="menu-profile-n"
                  :style="{ display: menuLogin ? 'block' : 'none' }"
                  :class="{ 'menu-profile-show': menuLogin }"
                >
                  <ul>
                    <li @click="$router.push({ name: 'login' })">
                      <i
                        class="far fa-sign-in-alt font-medium-1 mr-25 text-primary"
                        style="width: 20px"
                      />
                      <span class="txt-lot-dp">เข้าสู่ระบบ</span>
                    </li>

                    <li @click="$router.push({ name: 'register' })">
                      <i
                        class="far fa-user-plus font-medium-1 mr-25 text-primary"
                        style="width: 20px"
                      />
                      <span class="txt-lot-dp">สมัครสมาชิก</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            v-if="!userData"
            class="d-lg-none d-none d-md-block"
          >
            <div class="d-flex align-items-center">
              <div class="flag-lang mr-50">
                <div class="flag">
                  <img
                    src="/suvan/flag/flag-th.png"
                    alt="flag"
                    class="logo-flag-txt"
                  >
                </div>
              </div>

              <div
                class="user-icon"
                @click="showMenu"
              >
                <i class="fas fa-chevron-down mr-25" />
                <img
                  :src="ShowProfile()"
                  alt="img-user"
                  class="ic-profile"
                >

                <div
                  v-if="isMenuPro"
                  class="menu-profile"
                  :class="{ show: isMenuPro }"
                >
                  <ul>
                    <li
                      @click="$router.push({ name: 'login' })"
                    >
                      <i class="far fa-sign-in-alt mr-25" /> <span>เข้าสู่ระบบ</span>
                    </li>

                    <li
                      @click="$router.push({ name: 'register' })"
                    >
                      <i class="far fa-user-plus mr-25" /> <span>สมัครสมาชิก</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->

          <div class="d-block d-lg-none w-100">
            <div class="x-item-menu">
              <div class="logo-suvan">
                <a href="/">
                  <img
                    src="/logo/logo-sv.png"
                    alt=""
                    width="40"
                  >
                </a>
              </div>
              <div class="item-menu-lf">
                <div
                  class="d-flex align-items-center justify-content-center cursor-pointer"
                  @click="toggleMenu"
                >
                  <img
                    src="/suvan/menu.svg"
                    alt="menu-ic"
                    height="22"
                  >

                  <p class="mb-0 font-large-1 text-primary ml-50">
                    เมนู
                  </p>
                </div>

                <div
                  class="backdrops"
                  :style="{ display: isMenuOpen ? 'block' : 'none' }"
                  @click="closeMenu"
                />
                <div
                  class="menu-mobile"
                  :class="{ 'menu-mobile-show': isMenuOpen }"
                  :style="{ display: isMenuOpen ? 'block' : 'none' }"
                >
                  <ul>
                    <li>
                      <a href="/">
                        <div class="d-flex align-items-center menu-item-txt">
                          <img
                            src="/suvan/icon-menu/home.svg"
                            alt=""
                          >
                          <span>หน้าแรก</span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <div class="d-flex align-items-center menu-item-txt">
                        <img
                          src="/suvan/icon-menu/coin.svg"
                          alt=""
                        >
                        <span>การเงิน</span>
                      </div>

                      <div class="sub-menu-item">
                        <a href="/deposit/bank"> ฝากเงิน </a>
                        <a href="/withdraw"> ถอนเงิน </a>
                      </div>
                    </li>

                    <li>
                      <a href="/reward">
                        <div class="d-flex align-items-center menu-item-txt">
                          <img
                            src="/suvan/icon-menu/reward.svg"
                            alt=""
                          >
                          <span>ผลรางวัลสลากรวม

                            <img
                              src="/suvan/icon-menu/hot.gif"
                              alt="hot"
                              class="ml-n25"
                              style="width: 30px !important"
                            >
                          </span>
                        </div>
                      </a>
                    </li>

                    <li>
                      <div class="d-flex align-items-center menu-item-txt">
                        <img
                          src="/suvan/icon-menu/lotto.svg"
                          alt=""
                          style="margin-top: 2px"
                        >
                        <span>รายการสลาก</span>
                      </div>

                      <div class="sub-menu-item">
                        <a href="/check-result"> ตรวจผลสลากฯ </a>

                        <a href="/lottery/cart"> ตะกร้า </a>

                        <a href="/lottery/order"> กระเป๋าสลาก </a>
                      </div>
                    </li>

                    <li>
                      <div class="d-flex align-items-center menu-item-txt">
                        <img
                          src="/suvan/icon-menu/gift-fill.svg"
                          alt=""
                          style="margin-top: -2px"
                        >
                        <span>กิจกรรม</span>
                      </div>

                      <div class="sub-menu-item">
                        <a href="/point">
                          Point สะสมแต้ม
                          <img
                            src="/suvan/icon-menu/hot.gif"
                            alt="hot"
                            class="ml-n25"
                            width="30"
                          >
                        </a>

                        <a href="/lucky-box">
                          กล่องสุ่มเลข
                          <img
                            src="/suvan/icon-menu/hot.gif"
                            alt="hot"
                            class="ml-n25"
                            width="30"
                          >
                        </a>

                        <a href="/lucky-wheel"> กงล้อรับโชค </a>

                        <!-- <a href="/">
                          ทำนายฝัน
                        </a> -->
                      </div>
                    </li>

                    <li>
                      <a href="/contact">
                        <div class="d-flex align-items-center menu-item-txt">
                          <img
                            src="/suvan/icon-menu/phone.svg"
                            alt=""
                          >
                          <span>ติดต่อเจ้าหน้าที่</span>
                        </div>
                      </a>
                    </li>

                    <!-- <li>
                      <a href="/">
                        <div class="d-flex align-items-center menu-item-txt">
                          <img
                            src="/suvan/icon-menu/concent.svg"
                            alt=""
                          >
                          <span>นโยบายและเงื่อนไข</span>
                        </div>
                      </a>
                    </li> -->

                    <li>
                      <a href="/about">
                        <div class="d-flex align-items-center menu-item-txt">
                          <img
                            src="/suvan/icon-menu/contact.svg"
                            alt=""
                          >
                          <span>เกี่ยวกับเรา</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="item-profile-rt">
                <div
                  v-if="userData"
                  class="profile-item"
                >
                  <div class="detail-pro">
                    <p class="mb-0 text-primary">
                      {{ user.username }} <i class="fas fa-chevron-down" />
                    </p>

                    <div class="balance">
                      <p class="mb-0">
                        {{ Commas(balance ? balance : "0.00") }}
                        <span class="txt-won">₩</span>
                      </p>
                    </div>
                  </div>

                  <!-- Now -->

                  <div class="profile-img">
                    <div
                      class="user-icon"
                      style="position: relative"
                      @click="showMenu"
                    >
                      <img
                        :src="ShowProfile()"
                        alt="img-user"
                        class="ic-profile"
                      >

                      <div
                        class="backdrops"
                        :style="{ display: isMenuPro ? 'block' : 'none' }"
                        @click="closeMenuProfile"
                      />

                      <div
                        class="menuss"
                        :class="{ 'menu-active': isMenuPro }"
                      >
                        <ul>
                          <li @click="$router.push({ name: 'profile' })">
                            <i
                              class="fal fa-user-circle mr-25 text-primary font-medium-1"
                              style="width: 18px"
                            />
                            <span class="-txt-as">ข้อมูลส่วนตัว</span>
                          </li>

                          <li @click="$router.push({ name: 'invite-friend' })">
                            <i
                              class="fas fa-user-friends mr-25 text-primary font-medium-1"
                              style="width: 18px"
                            />
                            <span class="-txt-as">แนะนำเพื่อน</span>
                          </li>

                          <li @click="$router.push({ name: 'order-history' })">
                            <i
                              class="far fa-history mr-25 text-primary font-medium-1"
                              style="width: 18px"
                            />
                            <span class="-txt-as">ประวัติการซื้อ</span>
                          </li>
                        </ul>
                      </div>

                      <!-- <div
                        class="menu-profile-n"
                        :style="{ display: isMenuPro ? 'block' : 'none' }"
                        :class="{ 'menu-profile-show': isMenuPro }"
                      >
                        <ul>
                          <li
                            @click="$router.push({ name: 'profile' })"
                          >
                            <i
                              class="fal fa-user-circle mr-25 text-primary font-medium-1"
                              style="width: 18px;"
                            /> <span class="-txt-as">ข้อมูลส่วนตัว</span>
                          </li>

                          <li
                            @click="$router.push({ name: 'invite-friend' })"
                          >
                            <i
                              class="fas fa-user-friends mr-25 text-primary font-medium-1"
                              style="width: 18px;"
                            /> <span class="-txt-as">แนะนำเพื่อน</span>
                          </li>

                          <li
                            @click="$router.push({ name: 'order-history' })"
                          >
                            <i
                              class="far fa-history mr-25 text-primary font-medium-1"
                              style="width: 18px;"
                            /> <span class="-txt-as">ประวัติการซื้อ</span>
                          </li>
                        </ul>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div
                  v-else
                  class="d-flex align-items-center float-right"
                >
                  <div class="flag-lang mr-50">
                    <div class="flag">
                      <img
                        src="@/assets/images/newIcon/flag-th.png"
                        alt="flag"
                        class="logo-flag-txt"
                      >
                    </div>
                  </div>

                  <div
                    class="user-icon"
                    style="position: relative"
                    @click="showLogin"
                  >
                    <i class="fas fa-chevron-down mr-25" />
                    <img
                      :src="ShowProfile()"
                      alt="img-user"
                      class="ic-profile"
                    >
                    <div
                      class="backdrops"
                      :style="{ display: menuLogin ? 'block' : 'none' }"
                      @click="closeLogin"
                    />

                    <div
                      class="menu-profile-n"
                      :style="{ display: menuLogin ? 'block' : 'none' }"
                      :class="{ 'menu-profile-show': menuLogin }"
                    >
                      <ul>
                        <li @click="$router.push({ name: 'login' })">
                          <i
                            class="far fa-sign-in-alt font-medium-1 mr-25 text-primary"
                            style="width: 20px"
                          />
                          <span class="txt-lot-dp">เข้าสู่ระบบ</span>
                        </li>

                        <li @click="$router.push({ name: 'register' })">
                          <i
                            class="far fa-user-plus font-medium-1 mr-25 text-primary"
                            style="width: 20px"
                          />
                          <span class="txt-lot-dp">สมัครสมาชิก</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="userData"
            class="d-none d-lg-block"
          >
            <div
              class="profile-item cursor-pointer"
              @click="showMenu"
            >
              <div class="detail-pro-2">
                <p class="mb-0 text-primary">
                  {{ user.username }} <i class="fas fa-chevron-down" />
                </p>

                <div class="balance">
                  <p class="mb-0">
                    {{ Commas(balance ? balance : "0.00") }}
                    <small class="txt-won">₩</small>
                  </p>
                  <!--  -->
                </div>
              </div>

              <div class="profile-img">
                <div class="user-icon cursor-pointer">
                  <img
                    :src="ShowProfile()"
                    alt="img-user"
                    class="ic-profile"
                  >

                  <div
                    class="backdrops"
                    :style="{ display: isMenuPro ? 'block' : 'none' }"
                    @click="closeMenuProfile"
                  />

                  <div
                    class="menu-profile-n"
                    :style="{ display: isMenuPro ? 'block' : 'none' }"
                    :class="{ 'menu-profile-show': isMenuPro }"
                  >
                    <ul>
                      <li @click="$router.push({ name: 'profile' })">
                        <i
                          class="fal fa-user-circle mr-25 text-primary font-medium-1"
                          style="width: 18px"
                        />
                        <span class="-txt-as">ข้อมูลส่วนตัว</span>
                      </li>

                      <li @click="$router.push({ name: 'invite-friend' })">
                        <i
                          class="fas fa-user-friends mr-25 text-primary font-medium-1"
                          style="width: 18px"
                        />
                        <span class="-txt-as">แนะนำเพื่อน</span>
                      </li>

                      <li @click="$router.push({ name: 'order-history' })">
                        <i
                          class="far fa-history mr-25 text-primary font-medium-1"
                          style="width: 18px"
                        />
                        <span class="-txt-as">ประวัติการซื้อ</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view />

    <b-modal
      ref="my-welcome"
      hide-footer
      hide-header
      centered
    >
      <div class="text-center">
        <div class="closed">
          <i
            class="far fa-times cursor-pointer icon-close"
            @click="$refs['my-welcome'].hide()"
          />
        </div>

        <h4 class="mb-0 text-primary mt-2">
          กรุณาเข้าสู่ระบบเพื่อทำการสั่งซื้อ
        </h4>

        <button
          class="btn-gray-txt p-25 w-75 my-2"
          @click="$refs['my-welcome'].hide(), $router.push({ name: 'login' })"
        >
          ไปที่หน้าเข้าสู่ระบบ
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBToggle } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  name: 'ThisHeader',
  components: {
    BModal,
    // BSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      user: ['Suvarnaphumi'],
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      email: '',
      password: '',
      role: null,
      balance: 0,
      isMenuOpen: false,
      isMenuProfile: false,
      isMenuPro: false,
      menuLogin: false,
      showMenus: false,
    }
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('userData'))
    this.interval = setInterval(() => {
      // this.getUserslist()
      if (this.userData) {
        this.getBalance()
      }
    }, 5000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    if (this.userData) {
      this.getBalance()
    }
  },
  methods: {
    async betHuayja() {
      try {
        const { data } = await this.$http.get(
          'https://api.suvarnabhumilotto.com/api/ak/lotto/login',
        )
        if (data && data.url) {
          window.location.href = data.url
        } else {
          console.error('URL ไม่พบในข้อมูลที่ได้รับ')
        }
      } catch (error) {
        console.log(error)
      }
    },
    toggleMenus() {
      this.showMenus = !this.showMenus
    },
    showLogin() {
      this.menuLogin = !this.menuLogin
    },
    closeLogin() {
      // eslint-disable-next-line no-unused-expressions
      !this.menuLogin
    },
    showMenu() {
      this.isMenuPro = !this.isMenuPro
      // this.isMenuOpen = false
      // alert('show')
    },
    ShowProfile() {
      if (this.userData && this.userData.profile_img) {
        return this.userData.profile_img
      }
      return '/icon/user.png'
    },
    closeMenu() {
      this.isMenuOpen = false
    },
    closeMenuProfile() {
      this.isMenuProfile = false
    },
    toggleMenuProfile() {
      if (this.userData) {
        this.$router.push({ name: 'profile' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    toggleMenu() {
      // this.isMenuOpen = !this.isMenuOpen
      if (this.userData) {
        this.isMenuOpen = !this.isMenuOpen
        this.isMenuPro = false
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    toggleSidebar() {
      if (this.userData) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-1')
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    getBalance() {
      this.$http
        .get('balance/getbalance')
        .then(response => {
          this.balance = response.data.balance
          localStorage.setItem(
            'balance',
            JSON.stringify(response.data.balance),
          )
          localStorage.setItem(
            'roundth',
            JSON.stringify(response.data.roundth),
          )
        })
        .catch(error => {
          // จัดการข้อผิดพลาดในการส่งคำขอ HTTP อื่น ๆ ได้ที่นี่
          this.$router.push('/login') // สมมติว่ามีการใช้ Vue Router
          window.location.reload()
          console.error('Error:', error)
        })
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    login() {
      // this.$refs.loginForm.validate().then(success => {
      // if (success) {
      // console.log(this.email)
      useJwt
        .login({
          email: this.email,
          password: this.password,
          agent_id: 1,
        })
        .then(response => {
          const userData = response.data
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          // console.log(userData)
          this.role = userData.role
          this.email = ''
          this.password = ''
          this.hideModal()
          // this.getBalance()
          window.location.reload()

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ยินดีต้อนรับ',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'คุณเข้าสู่ระบบสำเร็จแล้ว',
            },
          })
          this.$ability.update(userData.ability)

          if (this.role === 'admin') {
            window.location.href = '/lottery'
          }
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
      // }
      // })
    },
    logout() {
      console.log('logout')
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      // this.$ability.update(initialAbility)
      this.role = null
      // Redirect to login page
      // this.$router.replace('/')
      window.location.replace('https://suvarnabhumilotto.com/')
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ไม่สามารถเข้าสู้ระบบได้ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.text2 {
  font-size: 1.5em;
  font-weight: 900;
  background-image: linear-gradient(
    to left,
    #f2cc8f 0.23%,
    #fffdf6 49.3%,
    #ab925d 99.84%
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
}
.box {
  padding: 5px 25px;
  height: 35;
  width: auto;
  border: 3px solid #0000;
  border-radius: 25px;
  background: linear-gradient(
        #ffb800 -4.17%,
        #d49e12 -4.15%,
        #e5bd56 18.88%,
        #ecc768 39.37%,
        #eecd78 60.49%,
        #ffeab3 86.1%,
        #fff2cf 92.5%,
        #ffe5a0 109.15%,
        #fffbf1 118.75%
      )
      padding-box,
    linear-gradient(var(--angle), #790f72 0%, #e5d6e6 50%, #790f72 100%)
      border-box;
  animation: 5s rotate linear infinite;
}

@media screen and (max-width: 320px) {
  .box {
    padding: 5px 10px;
    height: 25;
    font-size: 12px;
    width: auto;
    border: 2px solid #0000;
    border-radius: 25px;
    background: linear-gradient(
          #ffb800 -4.17%,
          #d49e12 -4.15%,
          #e5bd56 18.88%,
          #ecc768 39.37%,
          #eecd78 60.49%,
          #ffeab3 86.1%,
          #fff2cf 92.5%,
          #ffe5a0 109.15%,
          #fffbf1 118.75%
        )
        padding-box,
      linear-gradient(var(--angle), #790f72 0%, #e5d6e6 50%, #790f72 100%)
        border-box;
    animation: 5s rotate linear infinite;
  }
}

@keyframes rotate {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
</style>
